var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "adConfiguration" },
    [
      _vm.title
        ? _c("app-header", { attrs: { title: _vm.title, isShowBack: true } })
        : _vm._e(),
      _c(
        "el-form",
        { ref: "myForm", attrs: { model: _vm.myForm, rules: _vm.rules } },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                "label-width": "120px",
                label: "广告平台：",
                prop: "radio"
              }
            },
            [
              _vm.showHomepageAds
                ? _c(
                    "el-radio-group",
                    {
                      on: {
                        change: function($event) {
                          _vm.adTypeChange(_vm.myForm.radio)
                        }
                      },
                      model: {
                        value: _vm.myForm.radio,
                        callback: function($$v) {
                          _vm.$set(_vm.myForm, "radio", $$v)
                        },
                        expression: "myForm.radio"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("威富广告")
                      ]),
                      _c("el-radio", { attrs: { label: 2 } }, [
                        _vm._v("微信流量主")
                      ]),
                      _c("el-radio", { attrs: { label: 3 } }, [
                        _vm._v("皓量广告")
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.showHomepageAds
                ? _c(
                    "el-checkbox-group",
                    {
                      on: {
                        change: function($event) {
                          _vm.adTypeCheckboxChange(_vm.myForm.advertList)
                        }
                      },
                      model: {
                        value: _vm.myForm.advertList,
                        callback: function($$v) {
                          _vm.$set(_vm.myForm, "advertList", $$v)
                        },
                        expression: "myForm.advertList"
                      }
                    },
                    [
                      _c("el-checkbox", { attrs: { label: "威富广告" } }),
                      _c("el-checkbox", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.notShowWeChatAd,
                            expression: "!notShowWeChatAd"
                          }
                        ],
                        attrs: { label: "微信流量主" }
                      }),
                      _c("el-checkbox", { attrs: { label: "皓量广告" } })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.showHomepageAds
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "广告展示排序",
                    prop: "sort"
                  }
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "420px", display: "inline-block" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.myForm.sort,
                      callback: function($$v) {
                        _vm.$set(_vm.myForm, "sort", $$v)
                      },
                      expression: "myForm.sort"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.waveAdvertBanner
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "威富广告排序",
                    prop: "waveAdvertSort"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "420px", display: "inline-block" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.myForm.waveAdvertSort,
                        callback: function($$v) {
                          _vm.$set(_vm.myForm, "waveAdvertSort", $$v)
                        },
                        expression: "myForm.waveAdvertSort"
                      }
                    },
                    _vm._l(_vm.sortList, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          (_vm.showHomepageAds && _vm.myForm.radio == 1) || _vm.waveAdvertBanner
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "威富广告编码",
                    prop: "spaceCode"
                  }
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "420px", display: "inline-block" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.myForm.spaceCode,
                      callback: function($$v) {
                        _vm.$set(_vm.myForm, "spaceCode", $$v)
                      },
                      expression: "myForm.spaceCode"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          (_vm.showHomepageAds && _vm.myForm.radio == 2) ||
          _vm.wechatAdvertBanner
            ? _c(
                "div",
                { staticClass: "list-box" },
                [
                  _vm.wechatAdvertBanner
                    ? _c(
                        "el-form-item",
                        {
                          staticStyle: {
                            width: "539px",
                            display: "inline-block"
                          },
                          attrs: {
                            "label-width": "120px",
                            label: "微信广告排序",
                            prop: "wechatAdvertSort"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.myForm.wechatAdvertSort,
                                callback: function($$v) {
                                  _vm.$set(_vm.myForm, "wechatAdvertSort", $$v)
                                },
                                expression: "myForm.wechatAdvertSort"
                              }
                            },
                            _vm._l(_vm.sortList, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._l(_vm.flowList, function(item, index) {
                    return _c("div", { key: index, staticClass: "list-item" }, [
                      _c(
                        "div",
                        { staticClass: "list-col" },
                        [
                          _c("label", [
                            _c("span", { staticClass: "asterisk" }, [
                              _vm._v("*")
                            ]),
                            _vm._v("微信广告ID")
                          ]),
                          _c("el-input", {
                            attrs: { maxlength: "20", placeholder: "请输入" },
                            model: {
                              value: item.adID,
                              callback: function($$v) {
                                _vm.$set(
                                  item,
                                  "adID",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "item.adID"
                            }
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "list-col" }, [
                        _c("label", [
                          _c("span", { staticClass: "asterisk" }, [
                            _vm._v("*")
                          ]),
                          _vm._v("微信广告序号")
                        ]),
                        _c(
                          "div",
                          { staticClass: "width100" },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                on: {
                                  change: function($event) {
                                    _vm.wechatAdSerialNumberChange(index)
                                  }
                                },
                                model: {
                                  value: item.wechatAdSerialNumber,
                                  callback: function($$v) {
                                    _vm.$set(item, "wechatAdSerialNumber", $$v)
                                  },
                                  expression: "item.wechatAdSerialNumber"
                                }
                              },
                              _vm._l(_vm.adSerialNumberList, function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "list-col" }, [
                        _c("label", [
                          _c("span", { staticClass: "asterisk" }, [
                            _vm._v("*")
                          ]),
                          _vm._v("是否原生广告模板")
                        ]),
                        _c(
                          "div",
                          { staticClass: "width100" },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                on: {
                                  change: function($event) {
                                    _vm.primordialChange(index)
                                  }
                                },
                                model: {
                                  value: item.primordial,
                                  callback: function($$v) {
                                    _vm.$set(item, "primordial", $$v)
                                  },
                                  expression: "item.primordial"
                                }
                              },
                              _vm._l(_vm.primordialList, function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "list-col" },
                        [
                          _c("label", [
                            _c("span", { staticClass: "asterisk" }, [
                              _vm._v("*")
                            ]),
                            _vm._v("微信浏览主自动刷新时间")
                          ]),
                          _c("el-input", {
                            attrs: {
                              type: "number",
                              min: "1",
                              max: "30",
                              placeholder: "请输入"
                            },
                            model: {
                              value: item.refreshTime,
                              callback: function($$v) {
                                _vm.$set(
                                  item,
                                  "refreshTime",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "item.refreshTime"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "list-buts" },
                        [
                          _c("el-button", {
                            staticClass: "list-but",
                            attrs: { type: "primary", icon: "el-icon-plus" },
                            on: {
                              click: function($event) {
                                _vm.wechatFlowAddItem(index)
                              }
                            }
                          }),
                          _c("el-button", {
                            staticClass: "list-but",
                            attrs: { type: "danger", icon: "el-icon-minus" },
                            on: {
                              click: function($event) {
                                _vm.wechatFlowDelItem(index)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ])
                  })
                ],
                2
              )
            : _vm._e(),
          (_vm.showHomepageAds && _vm.myForm.radio == 3) ||
          _vm.haoliangAdvertBanner
            ? _c(
                "div",
                { staticClass: "list-box" },
                [
                  _vm.haoliangAdvertBanner
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            "label-width": "120px",
                            label: "皓量广告排序",
                            prop: "haoliangAdvertSort"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: {
                                width: "420px",
                                display: "inline-block"
                              },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.myForm.haoliangAdvertSort,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.myForm,
                                    "haoliangAdvertSort",
                                    $$v
                                  )
                                },
                                expression: "myForm.haoliangAdvertSort"
                              }
                            },
                            _vm._l(_vm.sortList, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._l(_vm.haoliangList, function(item, index) {
                    return _c("div", { key: index, staticClass: "list-item" }, [
                      _c(
                        "div",
                        { staticClass: "list-col" },
                        [
                          _c("label", [
                            _c("span", { staticClass: "asterisk" }, [
                              _vm._v("*")
                            ]),
                            _vm._v("皓量广告ID")
                          ]),
                          _c("el-input", {
                            attrs: { maxlength: "20", placeholder: "请输入" },
                            model: {
                              value: item.haolaingAdID,
                              callback: function($$v) {
                                _vm.$set(
                                  item,
                                  "haolaingAdID",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "item.haolaingAdID"
                            }
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "list-col" }, [
                        _c("label", [
                          _c("span", { staticClass: "asterisk" }, [
                            _vm._v("*")
                          ]),
                          _vm._v("广告序号")
                        ]),
                        _c(
                          "div",
                          { staticClass: "width100" },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { placeholder: "请选择" },
                                on: {
                                  change: function($event) {
                                    _vm.adSerialNumberChange(index)
                                  }
                                },
                                model: {
                                  value: item.adSerialNumber,
                                  callback: function($$v) {
                                    _vm.$set(item, "adSerialNumber", $$v)
                                  },
                                  expression: "item.adSerialNumber"
                                }
                              },
                              _vm._l(_vm.adSerialNumberList, function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "list-buts" },
                        [
                          _c("el-button", {
                            staticClass: "list-but",
                            attrs: { type: "primary", icon: "el-icon-plus" },
                            on: {
                              click: function($event) {
                                _vm.haoliangAddItem(index)
                              }
                            }
                          }),
                          _c("el-button", {
                            staticClass: "list-but",
                            attrs: { type: "danger", icon: "el-icon-minus" },
                            on: {
                              click: function($event) {
                                _vm.haoliangDelItem(index)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ])
                  })
                ],
                2
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "submitBox" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.submit()
                    }
                  }
                },
                [_vm._v("提交")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }