<template>
    <div class="adConfiguration">
        <app-header v-if="title" :title="title" :isShowBack="true"></app-header>
        <el-form ref="myForm" :model="myForm" :rules="rules">
            <el-form-item label-width="120px" label="广告平台：" prop="radio">
                <el-radio-group v-if="showHomepageAds" v-model="myForm.radio" @change="adTypeChange(myForm.radio)">
                    <el-radio :label="1">威富广告</el-radio>
                    <el-radio :label="2">微信流量主</el-radio>
                    <el-radio :label="3">皓量广告</el-radio>
                </el-radio-group>
                <el-checkbox-group v-if="!showHomepageAds" v-model="myForm.advertList"
                    @change="adTypeCheckboxChange(myForm.advertList)">
                    <el-checkbox label="威富广告"></el-checkbox>
                    <el-checkbox v-show="!notShowWeChatAd" label="微信流量主"></el-checkbox>
                    <el-checkbox label="皓量广告"></el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item v-if="showHomepageAds" label-width="120px" label="广告展示排序" prop="sort">
                <el-input style="width: 420px;display: inline-block;" v-model="myForm.sort" disabled>
                </el-input>
            </el-form-item>
            <el-form-item v-if="waveAdvertBanner" label-width="120px" label="威富广告排序" prop="waveAdvertSort">
                <el-select style="width: 420px;display: inline-block;" v-model="myForm.waveAdvertSort"
                    placeholder="请选择">
                    <el-option v-for="item in sortList" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item v-if="(showHomepageAds&&myForm.radio==1)||waveAdvertBanner" label-width="120px" label="威富广告编码"
                prop="spaceCode">
                <el-input style="width: 420px;display: inline-block;" v-model="myForm.spaceCode" disabled>
                </el-input>
            </el-form-item>
            <!-- 微信流量主 -->
            <div class="list-box" v-if="(showHomepageAds&&myForm.radio==2)||wechatAdvertBanner">
                <el-form-item style="width: 539px;display: inline-block;" v-if="wechatAdvertBanner" label-width="120px"
                    label="微信广告排序" prop="wechatAdvertSort">
                    <el-select v-model="myForm.wechatAdvertSort" placeholder="请选择">
                        <el-option v-for="item in sortList" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <div class="list-item" v-for="(item, index) in flowList" :key="index">
                    <div class="list-col">
                        <label><span class="asterisk">*</span>微信广告ID</label>
                        <el-input v-model.trim="item.adID" maxlength="20" placeholder="请输入"></el-input>
                    </div>
                    <div class="list-col">
                        <label><span class="asterisk">*</span>微信广告序号</label>
                        <div class="width100">
                            <el-select v-model="item.wechatAdSerialNumber" placeholder="请选择"
                                @change="wechatAdSerialNumberChange(index)">
                                <el-option v-for="item in adSerialNumberList" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="list-col">
                        <label><span class="asterisk">*</span>是否原生广告模板</label>
                        <div class="width100">
                            <el-select v-model="item.primordial" placeholder="请选择" @change="primordialChange(index)">
                                <el-option v-for="item in primordialList" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="list-col">
                        <label><span class="asterisk">*</span>微信浏览主自动刷新时间</label>
                        <el-input v-model.trim="item.refreshTime" type="number" min="1" max="30" placeholder="请输入">
                        </el-input>
                    </div>
                    <div class="list-buts">
                        <el-button class="list-but" type="primary" icon="el-icon-plus"
                            @click="wechatFlowAddItem(index)">
                        </el-button>
                        <el-button class="list-but" type="danger" icon="el-icon-minus"
                            @click="wechatFlowDelItem(index)">
                        </el-button>
                    </div>
                </div>
            </div>
            <!-- 皓量广告-->
            <div class="list-box" v-if="(showHomepageAds&&myForm.radio==3)||haoliangAdvertBanner">
                <el-form-item v-if="haoliangAdvertBanner" label-width="120px" label="皓量广告排序" prop="haoliangAdvertSort">
                    <el-select style="width: 420px;display: inline-block;" v-model="myForm.haoliangAdvertSort"
                        placeholder="请选择">
                        <el-option v-for="item in sortList" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <div class="list-item" v-for="(item, index) in haoliangList" :key="index">
                    <div class="list-col">
                        <label><span class="asterisk">*</span>皓量广告ID</label>
                        <el-input v-model.trim="item.haolaingAdID" maxlength="20" placeholder="请输入"></el-input>
                    </div>
                    <div class="list-col">
                        <label><span class="asterisk">*</span>广告序号</label>
                        <div class="width100">
                            <el-select v-model="item.adSerialNumber" placeholder="请选择"
                                @change="adSerialNumberChange(index)">
                                <el-option v-for="item in adSerialNumberList" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="list-buts">
                        <el-button class="list-but" type="primary" icon="el-icon-plus" @click="haoliangAddItem(index)">
                        </el-button>
                        <el-button class="list-but" type="danger" icon="el-icon-minus" @click="haoliangDelItem(index)">
                        </el-button>
                    </div>
                </div>
            </div>
            <div class="submitBox">
                <el-button type="primary" @click="submit()">提交</el-button>
            </div>

        </el-form>
    </div>
</template>
<script>
    let popUpWindowCode = "XCGG001";
    let myPageTopAdCode = "XCGG006";
    let openDoorBottomAdCode = "XCGG007";
    export default {
        data() {
            return {
                title: "广告配置",
                radio: 1,
                notShowWeChatAd: false,//是否显示微信流量主
                myForm: {
                    radio: 1,
                    sort: 1,
                    spaceCode: '',
                    advertList: ['威富广告'],
                    waveAdvertSort: "",
                    wechatAdvertSort: "",
                    haoliangAdvertSort: "",
                },
                rules: {
                    spaceCode: [{
                        required: true,
                        message: "请输入威富广告编码",
                        trigger: ['blur']
                    }],
                    radio: [{
                        required: true,
                        message: "请选择广告平台",
                        trigger: ['blur']
                    }],
                    sort: [{
                        required: true,
                        message: "请输入广告展示排序",
                        trigger: ['blur']
                    }],
                    waveAdvertSort: [{
                        required: true,
                        message: "请选择威富广告排序",
                        trigger: ['change']
                    }],
                    wechatAdvertSort: [{
                        required: true,
                        message: "请选择微信广告排序",
                        trigger: ['change']
                    }],
                    haoliangAdvertSort: [{
                        required: true,
                        message: "请选择皓量广告排序",
                        trigger: ['change']
                    }],

                },
                flowList: [],
                primordialList: [{
                        value: 1,
                        label: '是'
                    },
                    {
                        value: 2,
                        label: '否'
                    }
                ],
                adSerialNumberList: [],
                flowTemplate: {},
                haoliangList: [],
                haoliangTemplate: {},
                showHomepageAds: true,
                waveAdvertBanner: false,
                wechatAdvertBanner: false,
                haoliangAdvertBanner: false,
                waveAdvertSort: "",
                wechatAdvertSort: "",
                haoliangAdvertSort: "",
                flowItemMessageFlage: false,
                haoliangItemMessage: false,
                // platform: [],
                sortList: [
                    {
                        label: '1',
                        value: 1
                    },
                    {
                        label: '2',
                        value: 2
                    },
                    {
                        label: '3',
                        value: 3
                    }
                ] 
            }
        },
        activated() {
            this.$refs["myForm"].resetFields(); //初始化表单
            this.flowTemplate = {
                adID: '',
                wechatAdSerialNumber: '',
                primordial: '',
                refreshTime: '',
            };
            this.haoliangTemplate = {
                haolaingAdID: '',
                adSerialNumber: '',
            };
            let _list = [];
            let haoliangArray = [];
            _list.push(JSON.parse(JSON.stringify(this.flowTemplate)));
            haoliangArray.push(JSON.parse(JSON.stringify(this.haoliangTemplate)));
            this.flowList = _list;
            this.haoliangList = haoliangArray;
            this.adSerialNumberList = [];
            for (let i = 0; i < 20; i++) {
                this.adSerialNumberList.push({
                    value: i + 1,
                    label: (i + 1).toString()
                })
            }

            this.myForm.spaceCode = this.$route.query.spaceCode;
            if (this.$route.query.spaceCode == popUpWindowCode) {
                this.showHomepageAds = true;
                this.myForm.radio = 1;
                this.wechatAdvertBanner = false;
                this.waveAdvertBanner = false;
                this.haoliangAdvertBanner = false;
            } else {
                this.showHomepageAds = false;
                this.waveAdvertBanner = true;
                this.wechatAdvertBanner = false;
                this.haoliangAdvertBanner = false;
                this.myForm.advertList = ['威富广告'];
                this.myForm.waveAdvertSort = "";
                this.myForm.wechatAdvertSort = "";
                this.myForm.haoliangAdvertSort = "";
            } 
            if(this.$route.query.spaceCode==myPageTopAdCode || this.$route.query.spaceCode == openDoorBottomAdCode) {
               this.notShowWeChatAd  = true;
            }else {
                this.notShowWeChatAd  = false;
            }
            console.log(this.myForm.radio)
            console.log(this.myForm.advertList)
            // 请求详情
            this.getDetail();

        },
        created() {

        },
        methods: {
            submit() {
                this.$refs["myForm"].validate((valid) => {
                    if (!valid) {
                        return;
                    } else {
                        if ((this.myForm.spaceCode == popUpWindowCode && this.myForm.radio == 2) || this
                            .wechatAdvertBanner) {
                            this.flowItemMessageFlage = false;
                            for (let index = 0; index < this.flowList.length; index++) {
                                let flowItem = this.flowList[index];
                                if (flowItem.adID) {
                                    this.idVali(flowItem.adID, index,1);
                                    if(this.flowItemMessageFlage) {
                                        return;
                                    }
                                }
                                if (!flowItem.adID) {
                                    this.flowItemMessageFlage = true;
                                    return this.$message({
                                        showClose: true,
                                        message: "请输入序号为" + (index + 1) + "中的微信广告ID",
                                        type: "warning"
                                    });
                                } else if (!flowItem.wechatAdSerialNumber) {
                                    this.flowItemMessageFlage = true;
                                    return this.$message({
                                        showClose: true,
                                        message: "请选择序号为" + (index + 1) + "中的微信广告序号",
                                        type: "warning"
                                    });
                                } else if (!flowItem.primordial) {
                                    this.flowItemMessageFlage = true;
                                    return this.$message({
                                        showClose: true,
                                        message: "请选择序号为" + (index + 1) + "中的模板广告类型",
                                        type: "warning"
                                    });
                                } else if (!flowItem.refreshTime) {
                                    this.flowItemMessageFlage = true;
                                    return this.$message({
                                        showClose: true,
                                        message: "请输入序号为" + (index + 1) + "中的微信浏览主自动刷新时间",
                                        type: "warning"
                                    });
                                } else if (this.flowList[index].refreshTime && (this.flowList[index]
                                        .refreshTime < 30 || this.flowList[index].refreshTime > 600
                                    )) {
                                    this.flowItemMessageFlage = true;
                                    return this.$message({
                                        showClose: true,
                                        message: "序号" + (index + 1) +
                                            "中的微信浏览主自动刷新时间不规范，只能为30~600",
                                        type: "warning"
                                    });
                                } else if (this.flowList[index] && this.flowList[index + 1]) {
                                    // 判断微信广告ID是否存在重复
                                    if (this.flowList[index].adID == this.flowList[index + 1]
                                        .adID) {
                                        this.flowItemMessageFlage = true;
                                        return this.$message({
                                            showClose: true,
                                            message: "序号" + (index + 1) + "与" + (index +
                                                    2) +
                                                "中的微信广告ID相同",
                                            type: "warning"
                                        });
                                    }
                                    // 判断微信广告序号是否存在重复
                                    if (this.flowList[index].wechatAdSerialNumber == this.flowList[
                                            index + 1].wechatAdSerialNumber) {
                                        this.flowItemMessageFlage = true;
                                        return this.$message({
                                            showClose: true,
                                            message: "序号" + (index + 1) + "与" + (index +
                                                    2) +
                                                "中的微信广告序号相同",
                                            type: "warning"
                                        });
                                    }


                                }
                            }
                            if (this.flowItemMessageFlage) {
                                return;
                            }
                        }
                        if ((this.myForm.spaceCode == popUpWindowCode && this.myForm.radio == 3) || this
                            .haoliangAdvertBanner) {
                            this.haoliangItemMessage = false;
                            for (let index = 0; index < this.haoliangList.length; index++) {
                                let haoliangItem = this.haoliangList[index];
                                if (haoliangItem.haolaingAdID) {
                                    this.idVali(haoliangItem.haolaingAdID, index,2);
                                    if(this.haoliangItemMessage) {
                                        return;
                                    }
                                }
                                if (!haoliangItem.haolaingAdID) {
                                    this.haoliangItemMessage = true;
                                    return this.$message({
                                        showClose: true,
                                        message: "请输入序号为" + (index + 1) + "中的皓量广告ID",
                                        type: "warning"
                                    });
                                } else if (!haoliangItem.adSerialNumber) {
                                    this.haoliangItemMessage = true;
                                    return this.$message({
                                        showClose: true,
                                        message: "请选择序号为" + (index + 1) + "中的广告序号",
                                        type: "warning"
                                    });
                                }
                                if (this.haoliangList[index] && this.haoliangList[index + 1]) {
                                    if (this.haoliangList[index].haolaingAdID == this.haoliangList[
                                            index + 1].haolaingAdID) {
                                        this.haoliangItemMessage = true;
                                        return this.$message({
                                            showClose: true,
                                            message: "序号" + (index + 1) + "与" + (index +
                                                    2) +
                                                "中的皓量广告ID相同",
                                            type: "warning"
                                        });
                                    }
                                    if (this.haoliangList[index].adSerialNumber == this
                                        .haoliangList[index + 1].adSerialNumber) {
                                        this.haoliangItemMessage = true;
                                        return this.$message({
                                            showClose: true,
                                            message: "序号" + (index + 1) + "与" + (index +
                                                    2) +
                                                "中的广告序号相同",
                                            type: "warning"
                                        });
                                    }
                                }
                                if (this.haoliangItemMessage) {
                                    return
                                }

                            }
                        }
                        if (this.myForm.spaceCode != popUpWindowCode) {
                            let identicalFlage = false;
                            if (this.waveAdvertBanner && this.wechatAdvertBanner && (this.myForm
                                    .waveAdvertSort == this.myForm.wechatAdvertSort)) {
                                identicalFlage = true;
                                return this.$message({
                                    showClose: true,
                                    message: "威富广告排序和微信广告排序不能相同",
                                    type: "warning"
                                });
                            }
                            if (this.waveAdvertBanner && this.haoliangAdvertBanner && (this.myForm
                                    .waveAdvertSort == this.myForm.haoliangAdvertSort)) {
                                identicalFlage = true;
                                return this.$message({
                                    showClose: true,
                                    message: "威富广告排序和皓量广告排序不能相同",
                                    type: "warning"
                                });
                            }
                            if (this.wechatAdvertBanner && this.haoliangAdvertBanner && (this.myForm
                                    .wechatAdvertSort == this.myForm.haoliangAdvertSort)) {
                                identicalFlage = true;
                                return this.$message({
                                    showClose: true,
                                    message: "微信广告排序和皓量广告排序不能相同",
                                    type: "warning"
                                });
                            }
                            if (identicalFlage) {
                                return
                            }
                            if (this.myForm.advertList.length == 0) {
                                return this.$message({
                                    showClose: true,
                                    message: "请选择广告平台",
                                    type: "warning"
                                });
                            }
                        }
                        // 提交入参
                        let dto = {
                            advertiseSpaceCode: this.$route.query.spaceCode,
                            advertiseSpaceId: this.$route.query.id,
                        };
                        let list1 = [];
                        let list2 = [];
                        this.flowList.forEach(item => {
                            list1.push({
                                sort: item.wechatAdSerialNumber,
                                id: item.adID,
                                isTemplate: item.primordial == 1 ? 0 : 1,
                                intervals: item.refreshTime,
                            })
                        })
                        this.haoliangList.forEach(item => {
                            list2.push({
                                id: item.haolaingAdID,
                                sort: item.adSerialNumber,
                            })
                        })
                        // 开门弹窗入参
                        if (this.showHomepageAds) {
                            if (this.myForm.radio == 1) {
                                dto.wavePlatform = {
                                    spaceCode: this.myForm.spaceCode,
                                    sort: this.myForm.sort,
                                }
                            } else if (this.myForm.radio == 2) {
                                dto.wxPlatform = {
                                    sort: this.myForm.sort,
                                    list: list1
                                }
                            } else if (this.myForm.radio == 3) {
                                dto.adBrightPlatform = {
                                    sort: this.myForm.sort,
                                    list: list2,
                                }
                            }
                        } else {
                            if (this.waveAdvertBanner) {
                                dto.wavePlatform = {
                                    spaceCode: this.myForm.spaceCode,
                                    sort: this.myForm.waveAdvertSort,
                                }

                            }
                            if (this.wechatAdvertBanner) {
                                dto.wxPlatform = {
                                    sort: this.myForm.wechatAdvertSort,
                                    list: list1
                                }
                            }
                            if (this.haoliangAdvertBanner) {
                                dto.adBrightPlatform = {
                                    sort: this.myForm.haoliangAdvertSort,
                                    list: list2,
                                }
                            }
                        }
                        this.post('/landlord-service/advertisePlatformInfo/add', dto, {
                            isUseResponse: true,
                        }).then(res => {
                            console.log('dto:', dto)
                            if (res.data.code == 0) {
                                this.$message({
                                    showClose: true,
                                    message: '提交成功',
                                    type: "success"
                                });
                            }

                            this.getDetail();
                        })
                    }
                })
            },
            getDetail() {
                this.get('/landlord-service/advertisePlatformInfo/applet/detail?spaceId=' + this
                    .$route.query.id, {}, {
                        isNotShowError: true
                    }).then(res => {
                    if (res.length > 0) {
                        //存在且不是空对象
                        this.myForm.advertList = [];
                        this.waveAdvertBanner = false;
                        this.wechatAdvertBanner = false;
                        this.haoliangAdvertBanner = false;
                        if (res[0].wavePlatform && JSON.stringify(res[0].wavePlatform) != "{}") {
                            this.wavePlatform = res[0].wavePlatform;
                            if (this.showHomepageAds) {
                                this.myForm.radio = 1;
                                this.myForm.sort = res[0].wavePlatform.sort;
                            } else {
                                this.myForm.advertList.push('威富广告');
                                this.waveAdvertBanner = true;
                                this.myForm.waveAdvertSort = res[0].wavePlatform.sort;
                            }
                            this.myForm.spaceCode = res[0].wavePlatform.spaceCode;

                        }
                        if (res[0].wxPlatform && JSON.stringify(res[0].wxPlatform) != "{}") {
                            this.wxPlatform = res[0].wxPlatform;
                            if (this.showHomepageAds) {
                                this.myForm.radio = 2;
                                this.myForm.sort = res[0].wxPlatform.sort;
                            } else {
                                this.myForm.advertList.push('微信流量主');
                                this.wechatAdvertBanner = true;
                                this.myForm.wechatAdvertSort = res[0].wxPlatform.sort;
                            }

                            let array = [];
                            res[0].wxPlatform.list.forEach(item => {
                                array.push({
                                    wechatAdSerialNumber: item.sort,
                                    primordial: item.isTemplate == 0 ? 1 : 2,
                                    refreshTime: item.intervals,
                                    adID: item.id,

                                })
                            })
                            this.flowList = array;
                        }
                        if (res[0].adBrightPlatform && JSON.stringify(res[0].adBrightPlatform) != "{}") {
                            this.adBrightPlatform = res[0].adBrightPlatform;
                            if (this.showHomepageAds) {
                                this.myForm.radio = 3;
                                this.myForm.sort = res[0].adBrightPlatform.sort;
                            } else {
                                this.myForm.advertList.push('皓量广告');
                                this.haoliangAdvertBanner = true;
                                this.myForm.haoliangAdvertSort = res[0].adBrightPlatform.sort;
                            }
                            let array = [];
                            res[0].adBrightPlatform.list.forEach(item => {
                                array.push({
                                    adSerialNumber: item.sort,
                                    haolaingAdID: item.id,
                                })
                            })
                            this.haoliangList = array;

                        }
                    }
                }).catch(err => {
                    console.log(err.data.msg)
                })
            },
            adTypeChange(value) {
                console.log(value)
                this.myForm.radio = value;
            },
            adTypeCheckboxChange(value) {
                this.myForm.advertList = value;
                if (value.indexOf('威富广告') != -1) {
                    this.waveAdvertBanner = true
                } else {
                    this.waveAdvertBanner = false
                }
                if (value.indexOf('微信流量主') != -1) {
                    this.wechatAdvertBanner = true
                } else {
                    this.wechatAdvertBanner = false
                }
                if (value.indexOf('皓量广告') != -1) {
                    this.haoliangAdvertBanner = true
                } else {
                    this.haoliangAdvertBanner = false
                }
            },
            primordialChange(value) {},
            wechatAdSerialNumberChange(index) {},
            wechatFlowAddItem(index) {
                if (this.flowList.length == 20) {
                    this.$message({
                        showClose: true,
                        message: "最多添加至20项",
                        type: "warning"
                    });
                } else {
                    this.flowList.splice(index + 1, 0, JSON.parse(JSON.stringify(this.flowTemplate)));
                }
            },
            wechatFlowDelItem(index) {
                if (this.flowList.length == 1) {
                    this.$message({
                        showClose: true,
                        message: "至少保留1项",
                        type: "warning"
                    });
                } else {
                    this.flowList.splice(index, 1);
                }
            },
            haoliangAddItem(index) {
                if (this.haoliangList.length == 20) {
                    this.$message({
                        showClose: true,
                        message: "最多添加至20项",
                        type: "warning"
                    });
                } else {
                    this.haoliangList.splice(index + 1, 0, JSON.parse(JSON.stringify(this.haoliangTemplate)));
                }
            },
            haoliangDelItem(index) {
                if (this.haoliangList.length == 1) {
                    this.$message({
                        showClose: true,
                        message: "至少保留1项",
                        type: "warning"
                    });
                } else {
                    this.haoliangList.splice(index, 1);
                }
            },
            adSerialNumberChange(index) {},
            idVali(value, index,val) {
                var reg = /^[a-zA-Z0-9]{1,20}$/;
                if (!reg.test(value)) {
                    // val=1：微信流量主  val=2：皓量广告
                    if(val == 1) {
                        this.flowItemMessageFlage = true;
                    }else if(val == 2) {
                        this.haoliangItemMessage = true;
                    }
                    let msg;
                    if(val == 1) {
                        msg = "序号为" + (index + 1) + "的微信广告ID只能包含字母与数字,最大长度为20位";
                    }else if(val == 2) {
                        msg = "序号为" + (index + 1) + "的皓量广告ID只能包含字母与数字,最大长度为20位";
                    }
                    return this.$message({
                        showClose: true,
                        message: msg,
                        type: 'warning'
                    })
                    
                }
            }
        }
    }
</script>
<style lang="scss">
    .adConfiguration {
        .list-box {
            min-width: 1250px;

            .list-item {
                padding: 4px 0;
                margin-bottom: 6px;
                background-color: #eee;
                padding-bottom: 12px;
                padding-right: 135px;
                position: relative;
                padding-left: 17px;

                .list-idx {
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    font-size: 14px;
                    color: #606266;
                }

                .list-col {
                    display: inline-block;
                    margin-top: 10px;

                    label {
                        margin-left: 10px;
                        margin-right: 6px;
                        font-size: 14px;
                        color: #606266;
                        line-height: 40px;
                    }

                    .el-input {
                        width: 250px;
                    }

                    .el-name {
                        width: 120px;
                    }

                    .el-autocomplete {
                        .el-input {
                            width: 180px;
                        }
                    }

                    .inputsCal {
                        width: 200px;
                    }

                    .el-inputs {
                        width: 200px;
                    }

                    .el-btn {
                        margin-left: 10px;
                    }

                    .el-select {
                        display: inline-block !important;
                    }

                    .verCodeInput {
                        width: 100px;
                    }

                    .verCodeText {
                        color: #30BAC1;
                        font-size: 14px;
                        margin-left: 10px;
                        cursor: pointer;
                    }

                    .width100 {
                        display: inline-block;

                        .el-input {
                            width: 100px;
                        }

                        .el-date-editor--daterange.el-input__inner {
                            width: 230px;
                        }
                    }
                }

                .list-buts {
                    display: inline-block;
                    margin-left: 20px;
                }
            }
        }

        .asterisk {
            color: red;
            padding-right: 5px;
        }

        .submitBox {
            text-align: center;
        }
    }
</style>